<template>
  <b-row>
    <b-col>
      <b-card>
        <b-card-title class="mb-1">
          Resend Invitation
        </b-card-title>
        <b-card-sub-title class="font-small-3">
          Resend an invitation to a user that has not yet accepted their invitation.
        </b-card-sub-title>
        <div class="mt-1">
          <b-form-group label="Username">
            <b-input></b-input>
          </b-form-group>
          <b-button block variant="primary">Do it!</b-button>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card title="">
        <b-card-title class="mb-1">
          Confirm Signup
        </b-card-title>
        <b-card-sub-title class="font-small-3">
          Confirm a user that has not yet accepted their invitation.
        </b-card-sub-title>
        <div class="mt-1">
          <b-form-group label="Username">
            <b-input></b-input>
          </b-form-group>
          <b-button block variant="primary">Do it!</b-button>
        </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card title="">
        <b-card-title class="mb-1">
          Reset Password
        </b-card-title>
        <b-card-sub-title class="font-small-3">
         Reset a user's password.
        </b-card-sub-title>
       <div class="mt-1">
         <b-form-group label="Username">
           <b-input></b-input>
         </b-form-group>
         <b-button block variant="primary">Do it!</b-button>
       </div>
      </b-card>
    </b-col>
    <b-col>
      <b-card>
        <b-card-title class="mb-1">
          Set Temporary Password
        </b-card-title>
        <b-card-sub-title class="font-small-3">
          Set a temporary password for a user.
        </b-card-sub-title>
        <div class="mt-1">
          <b-form-group label="Username">
            <b-input></b-input>
          </b-form-group>
          <b-form-group label="Password">
            <b-input></b-input>
          </b-form-group>
          <b-button block variant="primary">Do it!</b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>


<script>
import {
  adminConfirmSignUp,
  adminResetUserPassword,
  adminResendUserInvitation,
  adminSetUserTemporaryPassword
} from '@/scripts/aws';

export default {
  name: 'CognitoTools'
}
</script>
